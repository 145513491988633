.MuiCardMedia-img {
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s; /* Animation */
  transform-origin: bottom;
}

.MuiCardMedia-img:hover {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.react_for_echarts {
  margin-top: 1em;
}

.svgIcons {
  display: inline;
  width: 24px;
  height: 24px;
}

.keyContainer {
    padding: 0.4em;
    outline: none;
    border-radius: 9px;
    height: 40vh;
}
.keyContainer:focus {
    box-shadow: 0 0 0 0px #f90; /* or whatever colour you'd prefer */
}
